.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.MuiListItem-padding {
    cursor: pointer !important;
}

.select__control {
    height: 56px !important;
}

.basic-multi-select {
    z-index: 1 !important;
}

.MuiInputBase-input {
    background-color: transparent !important;
}

.MuiTableHead-root {
    background-color: #006ac6;
    color: #fff;
}

.MuiDataGrid-selectedRowCount {
    color: white;
}

.MuiDataGrid-cell {
    border-left: 1px solid rgb(224, 224, 224);
}

.MuiTablePagination-displayedRows {
    margin-top: 15px;

}

.MuiTablePagination-selectLabel {
    margin-top: 15px;
}

.MuiTablePagination-root:last-child {
    color: white;
}

.MuiSvgIcon-root-MuiSelect-icon {
    color: white !important;
}

.MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    color: #fff !important;
}

.Toastify__toast-container {
    word-break: break-word;
}

.MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon {
    color: white !important;
}

.baseSearch {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    height: 58px;
}

.baseSearch input {
    font-size: 16px;
    font-style: italic;
    height: 100%;
    margin-top: 13px;
    padding-left: 51px;
    padding-top: 5px;
    border: 2px solid #e1e1e1 !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.react-confirm-alert-overlay {
    background: rgb(159 2 2 / 82%) !important;
    z-index: 1500 !important;
}

.deleteConfirm {
    width: 500px;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 45px;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.parent {
    display: grid;
    place-items: center;
}

.dashboard-container {
    margin: 10px;
    padding: 15px
}

.overview-info {
    background-color: #00cdd7;
    border: 1px solid #00cdd7;
    color: #fff;
    border-radius: 4px;
    margin-right: 5px;
    padding: 0px 5px 0px 5px;
    text-align: center;
    transition: all 0.2s ease;
    height: 124px;
}

.overview-info .overIcon {
    font-size: 50px;
    margin-top: 35px;
}


.overview-info:first-child {
    height: 100% !important;
    margin-left: 5px !important;
}

.overview-info h4 {
    color: #fff;
    font-size: 2rem;
    margin: 20px 0 0 0;
    text-align: center;
}

.overview-info i {
    font-size: 40px;
}

.overview-info .info {
    padding: 10px 0px 0px 10px;
}

.overview-info .info p {
    font-size: 14px;
    font-weight: bold;
}

.overview-info:active {
    background-color: #fff;
    color: #00cdd7;
    border-radius: 4px;
    cursor: pointer;
}

.overview-info-active {
    background-color: #fff;
    color: #00cdd7;
    border-radius: 4px;
    cursor: pointer;
}

.overview-info-active h4 {
    color: #00cdd7;
}

.MuiDataGrid-virtualScroller {
    background-color: #006ac612;
}

.MuiDataGrid-footerContainer {
    background-color: #006ac6;
}

.MuiDataGrid-columnHeaders {
    background-color: #0471DB;
}

.MuiDataGrid-columnHeadersInner {
    background-color: #006ac6;
}

.MuiCheckbox-indeterminate {
    color: #0dcaf0 !important;
}

.Mui-checked {
    color: #0dcaf0 !important;
}
